export default{
    computed: {
        displayMode(){
            return this.$store.state.layout.displayMode
        },
        pattern(){
            if(this.blok && (this.blok.patternLight && this.blok.patternLight.filename) || (this.blok.patternDark && this.blok.patternDark.filename)){
                switch(this.displayMode){
                    case 'dark':
                        if(this.blok.patternDark && this.blok.patternDark.filename) return this.blok.patternDark
                        break;
                    case 'light':
                        if(this.blok.patternLight && this.blok.patternLight.filename) return this.blok.patternLight
                        break;
                }
            }
            return null
        }
    },
}