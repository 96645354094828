
import pattern from '~/mixins/pattern.js';
export default {
    props : ['blok'],
    mixins: [pattern],
    computed : {
        debug(){
            return this.$route.query.debug;
        }
    },
}
